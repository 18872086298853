import React, { FC } from 'react';
import { useExperiments, useTranslation } from '@wix/fe-essentials-standalone';
import {
  Box,
  Image,
  Text,
  Divider,
  Button,
  IconButton,
} from '@wix/design-system';
import { useProfileContext } from '../../Contexts/ProfileContext';
import ProviderLanguages from '../ProviderLanguages';
import ProviderSocialLinks from '../ProviderSocialLinks';
import BackToExplorer from '../BackToExplorer';
import ProviderReviewSummary from '../ProviderReviewSummary';
import ProviderLocation from '../ProviderLocation';
import { fillProfileUrl } from '../../../utils/urlUtils';
import s from './ContactPanel.module.scss';
import { UploadExport } from '@wix/wix-ui-icons-common';
import { SHOW_MARKETPLACE_PROFILE_SHARE } from '@utils/constants';
import { useBi } from '@components/Hooks/useBi';
import { copyOfExploreResultsPageGenericClick } from '@wix/bi-logger-marketplace/v2';
import { useBiSessionContext } from '@components/Contexts/BiSessionContext';

type Props = {
  isMobileView?: boolean;
  onContactClicked?: () => void;
  copy?: (value: string) => Promise<void>;
};

const ContactPanel: FC<Props> = ({ isMobileView, onContactClicked, copy }) => {
  const {
    partnerProfile,
    isMyProfile,
    enabled,
    isRenderedInModal,
    host,
    entry,
  } = useProfileContext();
  const { t } = useTranslation();
  const logger = useBi();
  const { sessionId } = useBiSessionContext();

  const { experiments } = useExperiments({ readOnly: true });
  const isShowingShare = experiments.enabled(SHOW_MARKETPLACE_PROFILE_SHARE);
  const url = `${host}/marketplace/wix-partner/${partnerProfile.slug}`;

  const onCopyClicked = () => {
    logger.report(
      copyOfExploreResultsPageGenericClick({
        clickType: 'share',
        sessionId,
        origin,
        entry,
        exploreVersion: 'V2',
      }),
    );
  };

  return (
    <Box
      minWidth={isMobileView ? '100%' : '302px'}
      backgroundColor="rgba(0, 6, 36, 0.03)"
      direction="vertical"
      dataHook="profile-contact-panel"
    >
      <div className={!isMobileView ? s.fixedLeftbar : ''}>
        <Box
          paddingLeft="24px"
          paddingRight="24px"
          paddingTop={isMobileView ? '18px' : '0px'}
          direction="vertical"
        >
          <BackToExplorer />
          <div
            style={{
              border: '1px solid #FFFFFF',
              width: '88px',
              height: '88px',
              backgroundColor: '#FFFFFF',
              boxShadow:
                '0px 0px 6px rgba(0, 6, 36, 0.12), 0px 2px 4px rgba(0, 6, 36, 0.12)',
              borderRadius: '100px',
            }}
          >
            <Image
              alt={partnerProfile?.professionalInformation?.businessName || ''}
              borderRadius="100px"
              transparent
              width="90px"
              height="90px"
              src={fillProfileUrl(
                partnerProfile?.professionalInformation?.profileImage || '',
                180,
                180,
              )}
            />
            {isShowingShare && isMobileView && (
              <IconButton
                dataHook="profile-contact-panel-share"
                priority="secondary"
                size="medium"
                style={{ position: 'absolute', right: '24px' }}
                onClick={() => {
                  copy?.(url);
                  onCopyClicked();
                }}
              >
                <UploadExport />
              </IconButton>
            )}
          </div>
          <Box paddingTop="18px">
            <Text
              size={isMobileView ? 'medium' : 'small'}
              weight="normal"
              ellipsis
              maxLines={1}
              dataHook="contact-panel-business-name"
            >
              {partnerProfile.professionalInformation?.businessName}
            </Text>
          </Box>
          <Box paddingTop="6px">
            <Text
              size={isMobileView ? 'small' : 'tiny'}
              weight={isMobileView ? 'normal' : 'thin'}
              secondary
              ellipsis
              maxLines={2}
            >
              {partnerProfile.professionalInformation?.tagline}
            </Text>
          </Box>
          <Box paddingTop="18px">
            <ProviderReviewSummary isStickyMobileView={false} />
          </Box>
          <Box
            paddingTop="30px"
            paddingBottom={isMyProfile || !enabled ? '0px' : '30px'}
          >
            {!isMyProfile && enabled && !isRenderedInModal && (
              <Button
                dataHook="profile-contact-button"
                onClick={onContactClicked}
              >
                {t('marketplace-contact-button')}
              </Button>
            )}
          </Box>
          {!isMobileView && (
            <>
              <Divider />
              <ProviderLocation
                marginTop="24px"
                showTitle={false}
                isMobile={false}
              />
              <Box height="10px"></Box>
              <ProviderLanguages isMobile={false} />
            </>
          )}
        </Box>
      </div>
      {!isMobileView && (
        <div className={s.footer}>
          <Box backgroundColor="#F7F8F8" width="100%" direction="vertical">
            <Divider />
            <ProviderSocialLinks />
          </Box>
        </div>
      )}
    </Box>
  );
};

export default ContactPanel;

import { useEffect, useRef, useState } from 'react';

interface UseCopyToClipboardParams {
  onSuccess: () => void;
  onError: () => void;
  onClear: () => void;
}
export const useCopyToClipboard = ({
  onSuccess,
  onError,
  onClear,
}: UseCopyToClipboardParams) => {
  const [isCopied, setIsCopied] = useState(false);
  const timeoutId = useRef<NodeJS.Timeout | null>(null);

  const clearStates = () => {
    setIsCopied(false);
    onClear();
  };
  const copy = async (value: string) => {
    if (isCopied) return;

    try {
      await navigator.clipboard.writeText(value);
      setIsCopied(true);
      onSuccess();
    } catch (error) {
      setIsCopied(false);
      onError();
      if (timeoutId.current) {
        clearTimeout(timeoutId.current);
      }
    } finally {
      timeoutId.current = setTimeout(() => clearStates(), 2500);
    }
  };

  useEffect(() => {
    return () => {
      if (timeoutId.current) {
        clearTimeout(timeoutId.current);
      }
    };
  }, []);

  return { isCopied, copy };
};

import React, { FC, useState } from 'react';
import { useExperiments, useTranslation } from '@wix/fe-essentials-standalone';
import { Events } from 'react-scroll';
import { Text, Divider, Button } from '@wix/design-system';
import { copyOfExploreResultsPageGenericClick } from '@wix/bi-logger-marketplace/v2';
import { useProfileContext } from '@components/Contexts/ProfileContext';
import { useBiSessionContext } from '@components/Contexts/BiSessionContext';
import { useBi } from '@components/Hooks/useBi';
import LinkWithHref from '../LinkWithHref';
import s from './NavBar.module.scss';
import { UploadExport } from '@wix/wix-ui-icons-common';
import { SHOW_MARKETPLACE_PROFILE_SHARE } from '@utils/constants';
export const NAVBAR_HEIGHT_OFFSET = -106;

type Props = {
  isMobileView?: boolean;
  navigationRefs: any;
  copy?: (value: string) => Promise<void>;
};

export const Sections = {
  EMPTY: '',
  ABOUT: 'about',
  FEATURED_PROJECTS: 'featured-projects',
  SERVICES_AND_RATES: 'services-and-rates',
  REVIEWS: 'reviews',
};

const TOP_TAB_TYPE = 'top tab';

const NavBar: FC<Props> = ({ isMobileView, navigationRefs, copy }) => {
  const { t } = useTranslation();
  const logger = useBi();
  const { sessionId } = useBiSessionContext();
  const { entry, origin, isRenderedInModal, host, partnerProfile } =
    useProfileContext();
  const [navItemWasClicked, setNavItemWasClicked] = useState(Sections.ABOUT);
  const { experiments } = useExperiments({ readOnly: true });
  const isShowingShare = experiments.enabled(SHOW_MARKETPLACE_PROFILE_SHARE);
  const url = `${host}/marketplace/wix-partner/${partnerProfile.slug}`;

  const registerScrollEnds = (section: string) => {
    Events.scrollEvent.register('end', function (_to: any, _element: any) {
      setNavItemWasClicked(section);
      Events.scrollEvent.remove('end');
    });
  };

  const biGenericClick = (clickType: string, data?: string) => {
    logger.report(
      copyOfExploreResultsPageGenericClick({
        clickType,
        sessionId,
        origin,
        entry,
        exploreVersion: 'V2',
        data,
      }),
    );
  };

  const handleSetActive = () => {
    setNavItemWasClicked(Sections.EMPTY);
  };

  return (
    <div
      className={
        isMobileView
          ? s.scrollingWrapper
          : isRenderedInModal
          ? s.navnoHeader
          : s.nav
      }
      data-hook="profile-navbar"
    >
      <nav
        aria-label={t('profile-page-navigation-tabs-aria-label')}
        style={isMobileView ? { padding: '0 24px' } : { padding: '0 48px' }}
      >
        <ul style={{ margin: isShowingShare ? '12px 0' : '18px 0' }}>
          <li>
            <Text weight="thin" size="small">
              <LinkWithHref
                href="#about"
                className={navItemWasClicked === Sections.ABOUT ? s.active : ''}
                offset={NAVBAR_HEIGHT_OFFSET}
                activeClass={navItemWasClicked ? '' : s.active}
                onSetActive={handleSetActive}
                smooth
                spy
                to={Sections.ABOUT}
                data-hook="navbar-about-tab"
                onClick={() => {
                  registerScrollEnds(Sections.ABOUT);
                  biGenericClick(TOP_TAB_TYPE, 'about tab');
                  navigationRefs.current?.[Sections.ABOUT].focus({
                    preventScroll: true,
                  });
                }}
              >
                <span>{t('profile-page-nav-bar-about')}</span>
              </LinkWithHref>
            </Text>
          </li>
          <li>
            <Text weight="thin" size="small">
              <LinkWithHref
                href="#featured-projects"
                className={
                  navItemWasClicked === Sections.FEATURED_PROJECTS
                    ? s.active
                    : ''
                }
                onSetActive={handleSetActive}
                offset={NAVBAR_HEIGHT_OFFSET}
                activeClass={navItemWasClicked ? '' : s.active}
                smooth
                spy
                to={Sections.FEATURED_PROJECTS}
                hashSpy
                onClick={() => {
                  registerScrollEnds(Sections.FEATURED_PROJECTS);
                  biGenericClick(TOP_TAB_TYPE, 'featured projects tab');
                  navigationRefs.current?.[Sections.FEATURED_PROJECTS].focus({
                    preventScroll: true,
                  });
                }}
              >
                {t('profile-page-nav-bar-featured-projects')}
              </LinkWithHref>
            </Text>
          </li>
          <li>
            <Text weight="thin" size="small">
              <LinkWithHref
                href="#starting-rates"
                className={
                  navItemWasClicked === Sections.SERVICES_AND_RATES
                    ? s.active
                    : ''
                }
                onSetActive={handleSetActive}
                offset={NAVBAR_HEIGHT_OFFSET}
                activeClass={navItemWasClicked ? '' : s.active}
                smooth
                spy
                to={Sections.SERVICES_AND_RATES}
                hashSpy
                onClick={() => {
                  registerScrollEnds(Sections.SERVICES_AND_RATES);
                  navigationRefs.current?.[Sections.SERVICES_AND_RATES].focus({
                    preventScroll: true,
                  });
                  biGenericClick(TOP_TAB_TYPE, 'services and rates tab');
                }}
              >
                {t('profile-page-nav-bar-services-and-rates')}
              </LinkWithHref>
            </Text>
          </li>
          <li>
            <Text weight="thin" size="small">
              <LinkWithHref
                href="#reviews"
                className={
                  navItemWasClicked === Sections.REVIEWS ? s.active : ''
                }
                offset={NAVBAR_HEIGHT_OFFSET}
                activeClass={navItemWasClicked ? '' : s.active}
                smooth
                onSetActive={handleSetActive}
                spy
                to={Sections.REVIEWS}
                hashSpy
                onClick={() => {
                  registerScrollEnds(Sections.REVIEWS);
                  navigationRefs.current?.[Sections.REVIEWS].focus({
                    preventScroll: true,
                  });
                  biGenericClick(TOP_TAB_TYPE, 'reviews tab');
                }}
              >
                {t('profile-page-nav-bar-reviews')}
              </LinkWithHref>
            </Text>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </li>
          {isShowingShare && !isMobileView && (
            <Button
              priority="secondary"
              prefixIcon={<UploadExport />}
              size="small"
              onClick={() => {
                copy?.(url);
                biGenericClick('share');
              }}
              style={{ marginLeft: 'auto' }}
              dataHook="navbar-share"
            >
              {t('profile-page-nav-bar-share-cta')}
            </Button>
          )}
        </ul>
      </nav>
      {!isMobileView && <Divider />}
    </div>
  );
};

export default NavBar;

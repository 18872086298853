import React, { FC } from 'react';
import { useTranslation } from '@wix/fe-essentials-standalone';
import { Notification, SectionHelper } from '@wix/design-system';
import { StatusAlert, StatusComplete } from '@wix/wix-ui-icons-common';
import s from './CopyNotification.module.scss';

type Props = {
  showCopySuccessNotification: boolean;
  showCopyFailureNotification: boolean;
  isMobileView: boolean;
};
const CopyNotification: FC<Props> = ({
  showCopySuccessNotification,
  showCopyFailureNotification,
  isMobileView,
}) => {
  const { t } = useTranslation();
  if (isMobileView) {
    return (
      <>
        {showCopySuccessNotification && (
          <div className={s.mobileCopyNotification} style={{ width: '200px' }}>
            <SectionHelper
              appearance="success"
              size="small"
              dataHook="profile-link-copy"
            >
              <div className={s.mobileCopyNotificationContent}>
                <StatusComplete />
                {t('profile-page-nav-bar-link-copied')}
              </div>
            </SectionHelper>
          </div>
        )}
        {showCopyFailureNotification && (
          <div className={s.mobileCopyNotification} style={{ width: '230px' }}>
            <SectionHelper
              appearance="danger"
              size="small"
              dataHook="profile-link-copy"
            >
              <div className={s.mobileCopyNotificationContent}>
                <StatusAlert />
                {t('profile-page-nav-bar-link-copy-failed')}
              </div>
            </SectionHelper>
          </div>
        )}
      </>
    );
  }

  return (
    <>
      <Notification
        type="local"
        theme="success"
        show={showCopySuccessNotification}
        dataHook="profile-link-copy-notification-success"
      >
        <Notification.TextLabel>
          {t('profile-page-nav-bar-link-copied')}
        </Notification.TextLabel>
        <Notification.CloseButton />
      </Notification>
      <Notification
        type="local"
        theme="error"
        show={showCopyFailureNotification}
        dataHook="profile-link-copy-notification-failure"
      >
        <Notification.TextLabel>
          {t('profile-page-nav-bar-link-copy-failed')}
        </Notification.TextLabel>
        <Notification.CloseButton />
      </Notification>
    </>
  );
};

export default CopyNotification;
